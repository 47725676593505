import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// reactstrap components
import { Button, 
  Container, 
  Row, 
  Col, 
  UncontrolledTooltip,
  Modal,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input } from "reactstrap";
  import emailjs from "emailjs-com";

import classnames from "classnames";

class Propaganda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      formModal:false,
      email:'',
      celular:'',
      descricao:'',
      color: "navbar-transparent",
    };
  }
  toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  onCollapseExiting = () => {
    this.setState({
      collapseOut: "collapsing-out",
    });
  };
  onCollapseExited = () => {
    this.setState({
      collapseOut: "",
    });
  };
  toggleModal = (modalState) => {
    this.setState({
      [modalState]: !this.state[modalState],
    });
  };
  
  render() {
    const templateParams = {
      //nome: this.state.nome,
      email: this.state.email,
      mensagem: this.state.descricao,
      numero: this.state.celular,
    };
    const contatar = () => {
      emailjs
        .send(
          "service_5i7ekse",
          "template_5skpnxi",
          templateParams,
          "user_mcChCqbOwLczVJKg5LxmF"
        )
        .then(
          (response) => {
            this.setState({formModal:false})
            toast.success("Contato recebido! Entraremos em contato em breve.");
          },
          (err) => {
            toast.error("Algo deu errado, tente novamente.");
            console.log(err);
          }
        );
    };
    return (
      <div
        className="section section-download"
        data-background-color="black"
        id="download-section"
      >
        <Modal
            modalClassName="modal-black"
            isOpen={this.state.formModal}
            toggle={() => this.toggleModal("formModal")}
          >
            <div className="modal-header justify-content-center">
              <button
                className="close"
                onClick={() => this.toggleModal("formModal")}
              >
                <i className="tim-icons icon-simple-remove text-white" />
              </button>
              <div className="text-muted text-center ml-auto mr-auto">
                <h3 className="mb-0">Vamos bater um papo!</h3>
              </div>
            </div>
            <div className="modal-body">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup
                    className={classnames("input-group-alternative", {
                      "input-group-focus": this.state.emailFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      onChange={(e)=> this.setState({email:e.target.value})}
                      onFocus={(e) => this.setState({ emailFocus: true })}
                      onBlur={(e) => this.setState({ emailFocus: false })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup
                    className={classnames("input-group-alternative", {
                      "input-group-focus": this.state.passwordFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-mobile" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Número"
                      type="text"
                      onChange={(e)=> this.setState({celular:e.target.value})}
                      onFocus={(e) => this.setState({ passwordFocus: true })}
                      onBlur={(e) => this.setState({ passwordFocus: false })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Input
                    defaultValue={this.state.descricao}
                    onChange={(e)=> this.setState({descricao:e.target.value})}
                    placeholder="Descrição Rápida"
                    type="text"
                  />
                </FormGroup>

                <div className="text-center">
                  <Button 
                  className="my-4"
                  color="info" 
                  type="button"
                  onClick={contatar}
                  >
                    Enviar
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          {/* End Form Modal */}
        <img alt="..." className="path" src={require("assets/img/path1.png")} />
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h2 className="title">
                Cansado de Centrais Telefônicas Defeituosas?
              </h2>
              <h4 className="description">
                

                Nós da Panatel Telecom trabalhamos com Centrais Telefônicas de todos os tipos e variações, além é claro de possuir a própia solução modular.
                <br/> <br/>Necessita de manutenção especializada e certificada? Nós realizamos o <a style={{fontWeight:"bold",cursor:'pointer'}} onClick={() => this.toggleModal("formModal")}>serviço</a>.
               <br/> <br/>Necessita de uma solução mais arrojada ? Nós Temos 
                <Link to="conheca-nsip">
                <b style={{fontWeight:'bold'}}> NSip</b>
              </Link>

              </h4>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <Button
                className="btn-round"
                color="info"
                onClick={() => this.toggleModal("formModal")}
                role="button"
                size="lg"
              >
                Fale conosco
              </Button>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <br />
          <br />
          <Row className="row-grid align-items-center my-md">
            <Col lg="6">
              <h3 className="text-info font-weight-light mb-2">
                Obrigado por acessar nosso site!
              </h3>
              <h4 className="mb-0 font-weight-light">
                Você pode nos encontrar nessas plataformas.
              </h4>
            </Col>
            <Col className="text-lg-center btn-wrapper" lg="6">
              <Button
                className="btn-icon btn-round"
                //color="twitter"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=556132024646&text=Ol%C3%A1%20%2C%20estava%20acessando%20seu%20site%20e%20decidi%20entrar%20em%20contato."
                style={{background:'green'}}
                id="whatsapp"
                size="lg"
              >
                <i className="fab fa-whatsapp" />
              </Button>
              <UncontrolledTooltip delay={0} target="whatsapp">
                Envie um WhatsApp!
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-round"
                color="facebook"
                id="telefone"
                size="lg"
                href="tel:6132024646"
              >
                
                <i className="fa fa-phone" />
              </Button>
              <UncontrolledTooltip delay={0} target="telefone">
                Clique para ligar!
              </UncontrolledTooltip>
             {/* <Button
                className="btn-icon btn-round"
                color="github"
                href="https://github.com/creativetimofficial"
                id="tooltip877922017"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-github" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip877922017">
                Star on Github
              </UncontrolledTooltip> */}
            </Col>
          </Row>
        </Container>
        <ToastContainer />

      </div>
    );
  }
}

export default Propaganda;
