import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

class Typography extends React.Component {
  render() {
    return (
      <div className="section section-typo">
        <img alt="..." className="path" src={require("assets/img/path1.png")} />
        <img
          alt="..."
          className="path path1"
          src={require("assets/img/path3.png")}
        />
        <Container>
          <h3 className="title">Sobre Nós</h3>
          <div id="typography">
            <Row>
              <Col md="12">
                <div className="typography-line">
                  <h4>
                    <b>Surgimento</b>
                  </h4>
                </div>
                <div className="typography-line">
                  <p>
                    Estabelecida no mercado desde <b>1998</b>, a Panatel
                    Teleinformática é hoje uma distribuidora, revenda e
                    desenvolvedora de soluções de tecnologia. Autorizada das
                    maiores marcas de telecomunicações do país e qualificada
                    para instalações e reparos há mais de 20 anos
                  </p>
                </div>
                <div className="typography-line">
                  <h4>
                    <b>Confiabilidade</b>
                  </h4>
                </div>
                <div className="typography-line">
                  <p>
                    A Panatel além de pioneira em algumas tecnologias de
                    telecomunicações , conta com um time extremamente
                    especializado tanto em equipamentos padrões quanto modernos.
                    Atuando como distribuidora , revenda e autorizada Leucotron
                    , Intelbras , Panasonic, Polycom, Alcatel, Digistar, Siemens
                    , Cisco , Plantronics , Unify , Furukawa , Bargoa entre
                    outros .
                  </p>
                </div>

                <div className="typography-line">
                  <blockquote>
                    <p className="blockquote blockquote-info">
                      "Inventor é um homem que olha para o mundo em torno de si
                      e não fica satisfeito com as coisas como elas são. Ele
                      quer melhorar tudo o que vê e aperfeiçoar o mundo. É
                      perseguido por uma idéia, possuído pelo espírito da
                      invenção e não descansa enquanto não materializa seus
                      projetos." <br />
                      <br />
                      <small>Alexander Graham Bell</small>
                    </p>
                  </blockquote>
                </div>
              </Col>
            </Row>
          </div>
          <div className="space-50" />
        </Container>
      </div>
    );
  }
}

export default Typography;
