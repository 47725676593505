import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

class Examples extends React.Component {
  render() {
    return (
      <div
        className="section section-examples"
        data-background-color="black"
        id="conheca-nsip"
      >
        <img alt="..." className="path" src={require("assets/img/path1.png")} />
        <div className="space-50" />
        <Container className="text-center">
          <Row>
            <Col sm="6" style={{ marginBottom: 100 }}>
              <Link to="conheca-nsip">
                <img
                  alt="..."
                  className="img-raised"
                  src={require("assets/img/printLanding.png")}
                />
              </Link>
              <Button
                className="btn-simple btn-round"
                color="primary"
                to="conheca-nsip"
                tag={Link}
              >
                Conheça o NSip
              </Button>
            </Col>
            <Col sm="6">
              <Link to="conheca-pabx">
                <img
                  alt="..."
                  className="img-raised"
                  src={require("assets/img/printLanding.png")}
                />
              </Link>
              <Button
                className="btn-simple btn-round"
                color="primary"
                to="conheca-pabx"
                tag={Link}
              >
                Outras Soluções
              </Button>
            </Col>
            <br></br>
            <br></br>
            <br></br>
            <Col sm="6">
              <Link to="conheca-cabeamento">
                <img
                  alt="..."
                  className="img-raised"
                  src={require("assets/img/printLanding.png")}
                />
              </Link>
              <Button
                className="btn-simple btn-round"
                color="primary"
                to="conheca-cabeamento"
                tag={Link}
              >
                Cabeamento
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Examples;
