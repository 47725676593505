import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.1.0";
import "assets/demo/demo.css";

import Index from "views/Index.js";
import LandingPage from "views/examples/LandingPage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import ConhecaPabx from "views/examples/ConhecaPabx";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Index {...props} />} />
      <Route
        path="/conheca-nsip"
        render={(props) => <LandingPage {...props} />}
      />
      <Route
        path="/conheca-pabx"
        render={(props) => <ConhecaPabx {...props} />}
      />
      <Route
        path="/conheca-cabeamento"
        render={(props) => <LandingPage {...props} />}
      />
      <Route
        path="/login-painel"
        render={(props) => <RegisterPage {...props} />}
      />
      <Route
        path="/profile-page"
        render={(props) => <ProfilePage {...props} />}
      />
      {/*<Redirect from="/" to="/components" />*/}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
