import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// reactstrap components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";

import bigChartData from "variables/charts.js";

class ConhecaPabx extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("landing-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("landing-page");
  }

  render() {
    const notify = () => toast("Função EM BREVE");
    return (
      <>
        <ExamplesNavbar />
        <div className="wrapper">
          <div className="page-header">
            <img
              alt="..."
              className="path"
              src={require("assets/img/blob.png")}
            />
            <img
              alt="..."
              className="path2"
              src={require("assets/img/path2.png")}
            />
            <img
              alt="..."
              className="shapes triangle"
              src={require("assets/img/triunghiuri.png")}
            />
            <img
              alt="..."
              className="shapes wave"
              src={require("assets/img/waves.png")}
            />
            <img
              alt="..."
              className="shapes squares"
              src={require("assets/img/patrat.png")}
            />
            <img
              alt="..."
              className="shapes circle"
              src={require("assets/img/cercuri.png")}
            />
            <div className="content-center">
              <Row className="row-grid justify-content-between align-items-center text-left">
                <Col lg="6" md="6">
                  <h1 className="text-white">
                    Ligando <b>você</b> <br /> ao seu
                    <span className="text-white">
                      {" "}
                      <b>cliente</b>
                    </span>
                  </h1>
                  <p className="text-white mb-3">
                    NSIP A Tecnologia que possibilita a facilidade de
                    comunicação entre as maiores empresas do paísA Tecnologia
                    que possibilita a facilidade de comunicação entre as maiores
                    empresas do país
                  </p>
                  <div className="btn-wrapper mb-3">
                    <p className="category text-success d-inline">Descubra</p>
                    <Button
                      className="btn-link"
                      color="success"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      <i className="tim-icons icon-minimal-right" />
                    </Button>
                  </div>
                </Col>
                <Col lg="4" md="5">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/etherum.png")}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <section className="section section-lg">
            <section className="section">
              <img
                alt="..."
                className="path"
                src={require("assets/img/path4.png")}
              />
              <Container>
                <Row className="row-grid justify-content-between">
                  <Col className="mt-lg-5" md="5">
                    <Row>
                      <Col className="px-2 py-2" lg="6" sm="12">
                        <Card className="card-stats">
                          <CardBody>
                            <Row>
                              <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="tim-icons icon-trophy text-warning" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="numbers">
                                  <CardTitle tag="p">27,7%</CardTitle>
                                  <p />
                                  <p className="card-category">São Paulo</p>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col className="px-2 py-2" lg="6" sm="12">
                        <Card className="card-stats upper bg-default">
                          <CardBody>
                            <Row>
                              <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="tim-icons icon-coins text-white" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="numbers">
                                  <CardTitle tag="p">31,5%</CardTitle>
                                  <p />
                                  <p className="card-category">
                                    Distrito Federal
                                  </p>
                                </div>
                                <small
                                  style={{ fontSize: 10 }}
                                  href="https://g1.globo.com/economia/concursos-e-emprego/noticia/2020/06/03/home-office-pode-chegar-a-227percent-das-ocupacoes-nacionais-aponta-estudo-do-ipea.ghtml"
                                >
                                  Fonte: G1
                                </small>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="px-2 py-2" lg="6" sm="12">
                        <Card className="card-stats">
                          <CardBody>
                            <Row>
                              <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="tim-icons icon-gift-2 text-info" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="numbers">
                                  <CardTitle tag="p">26,7%</CardTitle>
                                  <p />
                                  <p className="card-category">
                                    Rio de Janeiro
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col className="px-2 py-2" lg="6" sm="12">
                        <Card className="card-stats">
                          <CardBody>
                            <Row>
                              <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                  <i className="tim-icons icon-credit-card text-success" />
                                </div>
                              </Col>
                              <Col md="8" xs="7">
                                <div className="numbers">
                                  <CardTitle tag="p">
                                    + 20 <b>mi</b>
                                  </CardTitle>
                                  <p />
                                  <p className="card-category">Pessoas</p>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <div className="pl-md-5">
                      <h1>Não fique para trás</h1>
                      <p>
                        Nos últimos 4 meses deste ano houve um crescimento
                        exponencial de empresas com tecnologia de atendimento
                        remoto , devido a pandemia.
                      </p>
                      <br />
                      <p>
                        Especialistas acreditam que o <b>Distrito Federal</b>{" "}
                        crescerá cerca de 31,5% em atendimento remoto (3,8% a
                        mais que São Paulo). Especula-se que <b>20 milhões </b>{" "}
                        de pessoas estarão trabalhando remotamente pós pandemia.
                      </p>
                      <p>
                        Nossa tecnologia é totalmente flexível , trazendo a
                        possibilidade do atendimento padrão ou remoto.
                      </p>
                      <br />
                      <a
                        className="font-weight-bold text-info mt-5"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Mostar mais{" "}
                        <i className="tim-icons icon-minimal-right text-info" />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </section>
          <section className="section section-lg">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png")}
            />
            <img
              alt="..."
              className="path2"
              src={require("assets/img/path5.png")}
            />
            <img
              alt="..."
              className="path3"
              src={require("assets/img/path2.png")}
            />
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <h1 className="text-center">Benefícios Disponíveis</h1>
                  <Row className="row-grid justify-content-center">
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-primary">
                          <i className="tim-icons icon-tv-2" />
                        </div>
                        <h4 className="info-title">Acompanhamento</h4>
                        <hr className="line-primary" />
                        <p>
                          Possuímos uma plataforma de acompanhamento em tempo
                          real da nossa central telefônica.
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-info">
                          <i className="tim-icons icon-world" />
                        </div>
                        <h4 className="info-title">Mobilidade</h4>
                        <hr className="line-primary" />
                        <p>
                          Nossa Solução permite o atendimento dentro e fora do
                          seu escritório mantendo a estrutura.
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-warning">
                          <i className="tim-icons icon-spaceship" />
                        </div>
                        <h4 className="info-title">Facilidade</h4>
                        <hr className="line-warning" />
                        <p>
                          A Central NSip facilita a visualização de ramais e
                          troncos , além de uma busca simplificada da suas
                          gravações.
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-success">
                          <i className="tim-icons icon-single-02" />
                        </div>
                        <h4 className="info-title">Versatilidade</h4>
                        <hr className="line-success" />
                        <p>
                          A Panatel por meio desta plataforma está preparada
                          para fornecer soluções que se adequem as suas
                          necessidades. Consulte os planos disponíveis.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          {/* <section className="section section-lg">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png")}
            />
            <img
              alt="..."
              className="path2"
              src={require("assets/img/path2.png")}
            />
            <Col md="12">
              <Card className="card-chart card-plain">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <hr className="line-info" />
                      <h5 className="card-category">Total Investments</h5>
                      <CardTitle tag="h2">Performance</CardTitle>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={bigChartData.data}
                      options={bigChartData.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
   
          </section>
           */}
          <section className="section section-lg section-coins">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path3.png")}
            />
            <Container>
              <Row>
                <Col md="4">
                  <hr className="line-info" />
                  <h1>
                    Escolha a opção{" "}
                    <span className="text-info">que melhor te atenda.</span>
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col md="4" style={{ marginBottom: 100 }}>
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/bitcoin.png")}
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">Intelbras</h4>
                          <span>Funcionalidades</span>
                          <hr className="line-primary" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>Até 10 Ramais</ListGroupItem>
                          <ListGroupItem>Fila de Atendimento</ListGroupItem>
                          <ListGroupItem>Gravação (Appliance)</ListGroupItem>
                          <ListGroupItem>
                            Monitoramento em tempo real
                          </ListGroupItem>

                          <ListGroupItem>SLA 8 Horas *</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        className="btn-simple"
                        color="primary"
                        onClick={notify}
                      >
                        Adquirir
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4" style={{ marginBottom: 100 }}>
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/etherum.png")}
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">Leucotron</h4>
                          <span>Funcionalidades</span>
                          <hr className="line-success" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>Até 50 Ramais</ListGroupItem>
                          <ListGroupItem>Fila de Atendimento</ListGroupItem>
                          <ListGroupItem>
                            Gravação (Gestão e Appliance)
                          </ListGroupItem>
                          <ListGroupItem>
                            Monitoramento em tempo real
                          </ListGroupItem>

                          <ListGroupItem>SLA 4 Horas *</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        className="btn-simple"
                        color="success"
                        onClick={notify}
                      >
                        Adquirir
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4" style={{ marginBottom: 100 }}>
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/etherum.png")}
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">Panasonic</h4>
                          <span>Funcionalidades</span>
                          <hr className="line-success" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>Até 50 Ramais</ListGroupItem>
                          <ListGroupItem>Fila de Atendimento</ListGroupItem>
                          <ListGroupItem>
                            Gravação (Gestão e Appliance)
                          </ListGroupItem>
                          <ListGroupItem>
                            Monitoramento em tempo real
                          </ListGroupItem>

                          <ListGroupItem>SLA 4 Horas *</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        className="btn-simple"
                        color="success"
                        onClick={notify}
                      >
                        Adquirir
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4" style={{ marginBottom: 100 }}>
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/etherum.png")}
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">NSip</h4>
                          <span>Funcionalidades</span>
                          <hr className="line-success" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>Até 50 Ramais</ListGroupItem>
                          <ListGroupItem>Fila de Atendimento</ListGroupItem>
                          <ListGroupItem>
                            Gravação (Gestão e Appliance)
                          </ListGroupItem>
                          <ListGroupItem>
                            Monitoramento em tempo real
                          </ListGroupItem>

                          <ListGroupItem>SLA 4 Horas *</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        className="btn-simple"
                        color="success"
                        onClick={notify}
                      >
                        Adquirir
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4" style={{ marginBottom: 100 }}>
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/ripp.png")}
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">Cisco</h4>
                          <span>Funcionalidades</span>
                          <hr className="line-info" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>QUALQUER COISA</ListGroupItem>
                          <ListGroupItem>OUTRA COISA</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        className="btn-simple"
                        color="info"
                        onClick={notify}
                      >
                        Adquirir
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          <Footer />
        </div>
        <ToastContainer />
      </>
    );
  }
}

export default ConhecaPabx;
