import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container>
          <Row>
            <Col md="3">
              <h1 className="title">Panatel® </h1>
            </Col>

            <Col md="3">
              <h3 className="title">Contate-nos : <br></br> <a  href="tel:6132024646">(61) 3202-4646</a></h3>
             
              <div className="btn-wrapper profile">
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="default"
                  href="https://api.whatsapp.com/send?phone=556132024646&text=Ol%C3%A1%20%2C%20estava%20acessando%20seu%20site%20e%20decidi%20entrar%20em%20contato."
                  id="tooltipwpp"
                  target="_blank"
                >
                  <i className="fab fa-whatsapp" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltipwpp">
                  Mensagem via Whatsapp
                </UncontrolledTooltip>
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="default"
                  href="mailto:comercial@panateltelecom.com.br?subject=Contato%20Autom%C3%A1tico&body=Ol%C3%A1%20%2C%20estava%20acessando%20seu%20site%20e%20decidi%20entrar%20em%20contato%20atrav%C3%A9s%20deste%20email."
                  id="tooltipgmail"
                  target="_blank"
                >
                  <i className="fab fa-google" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltipgmail">
                  Mensagem via E-mail
                </UncontrolledTooltip>
               
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
