import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import classnames from "classnames";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class ComponentsNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      formModal:false,
      email:'',
      celular:'',
      descricao:'',
      color: "navbar-transparent",
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.changeColor);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeColor);
  }
  changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      this.setState({
        color: "bg-info",
      });
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  onCollapseExiting = () => {
    this.setState({
      collapseOut: "collapsing-out",
    });
  };
  onCollapseExited = () => {
    this.setState({
      collapseOut: "",
    });
  };
  scrollToDownload = () => {
    document
      .getElementById("conheca-nsip")
      .scrollIntoView({ behavior: "smooth" });
  };
  toggleModal = (modalState) => {
    this.setState({
      [modalState]: !this.state[modalState],
    });
  };
  
  render() {
    const templateParams = {
      //nome: this.state.nome,
      email: this.state.email,
      mensagem: this.state.descricao,
      numero: this.state.celular,
    };
    const contatar = () => {
      emailjs
        .send(
          "service_5i7ekse",
          "template_5skpnxi",
          templateParams,
          "user_mcChCqbOwLczVJKg5LxmF"
        )
        .then(
          (response) => {
            this.setState({formModal:false})
            toast.success("Contato recebido! Entraremos em contato em breve.");
          },
          (err) => {
            toast.error("Algo deu errado, tente novamente.");
            console.log(err);
          }
        );
    };
    return (
      <Navbar
        className={"fixed-top " + this.state.color}
        color-on-scroll="100"
        expand="lg"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <span>Panatel® </span>
              Teleinformática
            </NavbarBrand>

            <button
              aria-expanded={this.state.collapseOpen}
              className="navbar-toggler navbar-toggler"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Modal
            modalClassName="modal-black"
            isOpen={this.state.formModal}
            toggle={() => this.toggleModal("formModal")}
          >
            <div className="modal-header justify-content-center">
              <button
                className="close"
                onClick={() => this.toggleModal("formModal")}
              >
                <i className="tim-icons icon-simple-remove text-white" />
              </button>
              <div className="text-muted text-center ml-auto mr-auto">
                <h3 className="mb-0">Vamos bater um papo!</h3>
              </div>
            </div>
            <div className="modal-body">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup
                    className={classnames("input-group-alternative", {
                      "input-group-focus": this.state.emailFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      onChange={(e)=> this.setState({email:e.target.value})}
                      onFocus={(e) => this.setState({ emailFocus: true })}
                      onBlur={(e) => this.setState({ emailFocus: false })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup
                    className={classnames("input-group-alternative", {
                      "input-group-focus": this.state.passwordFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-mobile" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Número"
                      type="text"
                      onChange={(e)=> this.setState({celular:e.target.value})}
                      onFocus={(e) => this.setState({ passwordFocus: true })}
                      onBlur={(e) => this.setState({ passwordFocus: false })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Input
                    defaultValue={this.state.descricao}
                    onChange={(e)=> this.setState({descricao:e.target.value})}
                    placeholder="Descrição Rápida"
                    type="text"
                  />
                </FormGroup>

                <div className="text-center">
                  <Button 
                  className="my-4"
                  color="info" 
                  type="button"
                  onClick={contatar}
                  >
                    Enviar
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          {/* End Form Modal */}
          <Collapse
            className={"justify-content-end " + this.state.collapseOut}
            navbar
            isOpen={this.state.collapseOpen}
            onExiting={this.onCollapseExiting}
            onExited={this.onCollapseExited}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    Panatel
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button
                    aria-expanded={this.state.collapseOpen}
                    className="navbar-toggler"
                    onClick={this.toggleCollapse}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>
              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  href="/conheca-nsip"
                  rel="noopener noreferrer"
                  //target="_blank"
                  //title="Follow us on Instagram"
                >
                  <i className="tim-icons icon-spaceship" />
                  NSip
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fa fa-cogs d-lg-none d-xl-none" />
                  Área de Clientes
                </DropdownToggle>
                <DropdownMenu className="dropdown-with-icons">
                  <DropdownItem tag={Link} to="/login-painel">
                    <i className="tim-icons icon-laptop" />
                    Login Painel
                  </DropdownItem>
                  {/* <DropdownItem tag={Link} to="/conheca-nsip">
                    <i className="tim-icons icon-image-02" />
                    Landing Page
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/profile-page">
                    <i className="tim-icons icon-single-02" />
                    Profile Page
                  </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>

              {/* <NavItem>
                <Button
                  className="nav-link d-none d-lg-block"
                  color="success"
                  onClick={this.scrollToDownload}
                >
                  <i className="tim-icons icon-minimal-down" /> Soluções
                </Button>
              </NavItem> */}
              <NavItem>
                <Button
                  className="nav-link d-none d-lg-block"
                  color="default"
                  onClick={() => this.toggleModal("formModal")}
                >
                  Contate-nos
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
        <ToastContainer />
      </Navbar>
    );
  }
}

export default ComponentsNavbar;
